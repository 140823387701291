/* mobile first */
a.whats-a { 
  display: none;

  @media (min-width: 769px) {
    display: block;
    position: fixed; 
    right: 10px; 
    bottom: 10px; 
    filter: drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.3));
  }
}
