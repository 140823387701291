.gb_Sc .gb_Oc {
  &::-webkit-scrollbar {
    width: 16px;
    height: 16px;
  }

  &::-webkit-scrollbar-button {
    height: 0;
    width: 0;
  }
  
  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }
  
  &::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    background-color: transparent;
    border: 0 solid transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
    min-height: 28px;
  }
  
  &::-webkit-scrollbar-track {
    border: 0 solid transparent;
    background-clip: padding-box;
    background-color: transparent;
  }
}

.gb_Mc.gb_za {
  -webkit-transform: translateX(0);
  transform: translateX(0);
  visibility: visible;
  -webkit-box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
  box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
  -webkit-transition: transform .25s cubic-bezier(0.4,0.0,0.2,1),visibility 0s linear 0s;
  transition: transform .25s cubic-bezier(0.4,0.0,0.2,1),visibility 0s linear 0s;
}

.gb_Mc {
  width: 255px;
  -webkit-transform: translateX(-255px);
  transform: translateX(-255px);
}

.gb_Mc {
  bottom: 0;
  height: -webkit-calc(100vh - 100% + 60px);
  height: calc(100vh - 100% + 60px);
  overflow-y: auto;
  overflow-x: hidden;
  position: fixed;
  top: -1px;
  left: -1px;
  z-index: 2;
  will-change: visibility;
  visibility: hidden;
  -webkit-transition: transform .25s cubic-bezier(0.4,0.0,0.2,1),visibility 0s linear .25s;
  transition: transform .25s cubic-bezier(0.4,0.0,0.2,1),visibility 0s linear .25s;
}

.gb_Fc {
  border-radius: 50%;
  -webkit-border-radius: 50%;
  margin: 0 4px;
  padding: 12px;
  overflow: hidden;
  vertical-align: middle;
  cursor: pointer;
  height: 24px;
  width: 24px;
  user-select: none;
  -webkit-user-select: none;
  flex: 0 0 auto;
  -webkit-flex: 0 0 auto;
}

.icon.is-white:hover {
  background-color: rgba(60,64,67,.08);
  border-color: transparent;
  color: #0a0a0a;
}

.sidebar.dark {
  background-color: #343a40 !important;

  .logo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 1rem;
    border-bottom: 1px solid #4b545c;
    height: 60px;
    max-height: 60px;

    .icon {
      background-color: #4b545c;
      border-radius: 0.5rem;
      padding: 0.5rem;
      cursor: pointer;

      &:hover { 
        background-color: #5a656e;
      }
    }
  }

  .menu {
    width: 100%;
  }

  .menu-label {
    color: #fff;
  }

  .menu-list a {
    color: #c2c7d0;

    &:hover {
      background-color: rgba(255,255,255,.1);
      color: #fff;
    }
  }
}
