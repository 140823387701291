.dropdown-credit-card {
  width: 100%;

  .dropdown-trigger {
    width: 100%;
  }

  .dropdown-menu {
    min-width: 22rem;

    .dropdown-content {
      padding: 0.3rem;
      border-radius: 0.5rem;
      background-color: #fff;
  
      a.dropdown-item {
        padding: 0.3rem;

        &:hover {
          background-color: #fff !important;
        }
      }
    }
  }
}
