/* mobile first */
.footer-menu {
  z-index: 1;
  width: 100%;
  box-shadow: 0 -4px 6px -1px rgba(0,0,0,0.1),0 2px 4px -1px rgba(0,0,0,0.06);
  display: flex;
  position: fixed;
  bottom: 0;
  align-items: center;
  justify-content: space-around;
  flex-shrink: 0;
  height: 4rem;
  background-color: var(--shape);
}

.item-menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 5rem;
  padding: 0.25rem;
  color: var(--text-title);

  svg {
    font-size: 1.5rem;
    color: var(--text-body);
  }
}

.item-menu span {
  font-size: 1rem;
  margin-top: 0.25rem;
}

.item-menu--chevron {
  display: none;
}

.footer-menu--plus {
  .dropdown-content {
    border-radius: 0.5rem;
    background-color: rgba(66,66,66,0.98);
    
    .dropdown-item {
      color: var(--shape);
      padding: 0.6rem 3rem 0.6rem 1rem;
      font-size: 1.2rem;
    
      svg {
        color: var(--text-body);
      }
    
      &:hover {
        background-color: #606060;
        color: var(--shape);
      }
    }
  }
}

@media (min-width: 769px) {
  .footer-menu {
    position: sticky;
    top: 3rem;
    flex-direction: column;
    align-items: flex-start;
    height: fit-content;
    padding: 0.5rem 0rem;
    gap: 0rem;
    border-radius: 0.5rem;
  }

  .item-menu {
    flex-direction: row;
    gap: 1rem;
    justify-content: flex-start;
    align-items: center;
  }

  .item-menu--chevron {
    color: var(--text-title);
    display: block;
  }

  .item-menu--group:hover {
    background-color: #dde0ec;
    border-left: 2px solid var(--text-title);
  }

  .footer-menu--plus {
    .dropdown-content {    
      .dropdown-item {
        color: var(--shape);
        font-size: 1rem;
      }
    }
  }
}
