.quick-access-menu {
  z-index: 1;
  width: 100%;
  box-shadow: 0 -4px 6px -1px rgba(0,0,0,0.1),0 2px 4px -1px rgba(0,0,0,0.06);
  display: flex;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  align-items: center;
  justify-content: space-around;
  flex-shrink: 0;
  height: 4rem;
  background-color: var(--shape);
  
  .item-menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 7rem;
    padding: 0.25rem;
    cursor: pointer;
    color: var(--text-title);
  }
  
  .item-menu span {
    font-size: 0.9rem;
    margin-top: 0.25rem;
  }
  
  .item-menu svg {
    font-size: 1.5rem;
  }
}
