.tabs {
  margin-bottom: 0.8rem !important;
}

.tabs a {
  color: var(--text-title);
}

.tabs.is-toggle li.is-active a {
  background-color: var(--blue);
  border-color: var(--blue);
}

.box {
  padding: 0.7rem 1.25rem;

  &:not(:last-child) {
    margin-bottom: 0.8rem;
  }
}