.layout{
  margin-top: 60px;
  padding-top: 1.5rem !important;
  overflow-y: auto;
  // min-height: 700px;

  &::-webkit-scrollbar {
    height: 16px; // barra de rolagem vertical
  }

  &::-webkit-scrollbar-thumb {
    height: 56px;
    border-radius: 8px;
    border: 4px solid transparent;
    background-clip: content-box;
    background-color: hsl(0,0%,67%);;
  }

  @media (max-width: 768px) {
    min-height: 100%;
  }
}
