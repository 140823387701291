.tabs {
  margin-bottom: 0.8rem !important;
}

.tabs a {
  color: var(--text-title);
}

.tabs.is-toggle li.is-active a {
  background-color: var(--blue);
  border-color: var(--blue);
}

.box {
  padding: 0.7rem 1.25rem;

  &:not(:last-child) {
    margin-bottom: 0.8rem;
  }
}

form.card-form {
  .card-generic {
    min-height: 5rem;
    border-radius: 1.5rem;
    background-color: #424242;
    color: var(--shape);

    h1 {
      font-size: xx-large;
      font-weight: 600;
      letter-spacing: 0.3rem;
    }
  }

  input {
    width: 100%;
    padding: 0 1.5rem;
    height: 3rem;
    border-radius: 0.25rem;

    border: 1px solid #d7d7d7;
    background: var(--input-background);

    font-weight: 400;
    font-size: 1rem;

    &::placeholder {
      color: var(--text-body);
    }

    & + input,
    & + div {
      margin-top: 1rem;
    }
  }

  button {
    width: 100%;
    padding: 0 1.5rem;
    height: 3rem;
    color: #fff;
    border-radius: 1rem;
    border: 0;
    font-size: 1rem;
    font-weight: 500;

    transition: filter 0.2s;

    &:hover {
      filter: brightness(0.9);
    }

    & + button {
      margin-top: 1rem;
    }

    &[type='submit'] {
      background: var(--green);
    }

    &[type='button'] {
      background: var(--blue);
    }
  }
}