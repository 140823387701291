input.start-date,
input.end-date {
  width: 100%;
  padding: 0 1.5rem;
  height: 3rem;
  border-radius: 0.25rem;

  border: 1px solid #d7d7d7;
  background: var(--input-background);

  font-weight: 400;
  font-size: 1rem;

  &::placeholder {
    color: var(--text-body);
  }
}

.btn {
  width: 100%;
  padding: 0 1.5rem;
  height: 3rem;
  color: #fff;
  /* background-color: var(--green); */
  border-radius: 1rem;
  border: 0;
  font-size: 1rem;
  font-weight: 500;

  transition: filter 0.2s;

  &:hover {
    filter: brightness(0.9);
    color: #fff;
  }

  & + .btn {
    margin-top: 1rem;
  }
}

.success {
  background-color: var(--green);
}

.primary {
  background-color: var(--blue);
}

.danger {
  background-color: var(--red);
}