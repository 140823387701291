.button-payment {
  img {
    width: 15px;
    height: 15px;
    margin-right: 8px;
    filter: none;
  }
}

.button-payment.is-custom-purple.is-outlined:hover, 
.button-payment.is-custom-purple.is-outlined:focus, 
.button-payment.is-custom-purple.is-outlined.is-hovered {
  background-color: transparent;
  border-color: #0c4891;
  color: #0c4891;
}

.button-payment.active.is-custom-purple,
.button-payment.active.is-custom-purple.is-outlined:hover, 
.button-payment.active.is-custom-purple.is-outlined:focus, 
.button-payment.active.is-custom-purple.is-outlined.is-focused,
.button-payment.active.is-custom-purple.is-outlined.is-hovered {
  background-color: rgb(51, 204, 149);
  border-color: rgb(51, 204, 149);
  color: #fff;

  img {
    filter: brightness(0) invert(1);
  }
}