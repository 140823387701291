@import "../../../styles/custom_colors";

.no-suggestions {
  color: rgb(90, 89, 156);
  // padding: 0.5rem;
}

.suggestions {
  background-color: white;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  list-style: none;
  margin-top: -0.5rem;
  max-height: 10.3rem;
  overflow-y: auto;
  padding-left: 0;
  position: absolute;
  width: calc(300px + 1rem);
  z-index: 1;
}

.suggestions li {
  padding: 0.5rem;
}

.suggestion-active { 
  background-color: #dee2e6; 
}

.suggestions li:hover {
  background-color: $custom-purple;
  color: #fff;
  cursor: pointer;
}

.suggestions li:not(:last-of-type) {
  border-bottom: 1px solid #dbdbdb;
}
