.navbar.is-fixed-top {
  z-index: 3 !important;
}

nav.navbar {
  box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
  height: 60px;
  max-height: 60px;

  .navbar-brand {
    align-items: center;
    img {
      max-height: 50px;
    }
  }

  .navbar-item {
    img {
      max-height: 48px;
    }
  }

  img.is-rounded {
    border-radius: 50%;

    &:hover {
      -webkit-box-shadow: 0 1px 6px 0 rgb(32 33 36 / 28%);
      box-shadow: 0 1px 6px 0 rgb(32 33 36 / 28%);
    }
  }

  .dropdown {
    height: 48px;
  }

  .dropdown-menu {
    top: 54px;
    min-width: 20rem;
  }

  .dropdown-content {
    border-radius: 0.5rem;
    box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
  }

  .dropdown-item {
    color: var(--text-title);
    padding-right: 1rem;
  }
}

@media screen and (max-width: 1023px) {
  nav.navbar {
    .container {
      padding: 6px;
      justify-content: space-between;
    }

    .navbar-brand {
      justify-content: space-between;
    }
  }
}

@media (max-width: 768px) {
  nav.navbar {
    .navbar-brand {
      justify-content: space-between;
      img {
        max-height: 35px;
      }
    }
  }
}
