.box {
  border: 1px solid lightgrey;
  box-shadow: revert;
}

.box:hover {
  border: 1px solid lightgrey;
  box-shadow: 0 0.5em 1em -0.125em rgb(10 10 10 / 10%),
    0 0px 0 1px rgb(10 10 10 / 2%);
}

.occupation-by-area.box:hover {
  border: 1px solid lightgrey;
  box-shadow: revert;
}