.control.has-icons-right {
  .icon {
    cursor: pointer;
    font-size: 14px;
    height: 2.9em;
    pointer-events: revert;
    transition: opacity 0.5s ease 0s;
    
    &.close-eye {
      opacity: 0.7;
    }

    &.is-right {
      right: 5px;
    }
  }
}
