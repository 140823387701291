@import "./custom_colors";

.users{
  padding-top: 40px !important;

  .card-content{
    padding: 40px;
  }
  .users-edit{
    .title{
      margin-bottom: 15px;
    }
  }
}
