.button-renew.is-custom-purple.is-outlined:hover, 
.button-renew.is-custom-purple.is-outlined:focus, 
.button-renew.is-custom-purple.is-outlined.is-focused,
.button-renew.is-custom-purple.is-outlined.is-hovered {
  background-color: transparent;
  border-color: #0c4891;
  color: #0c4891;
}

.button-renew.active.is-custom-purple,
.button-renew.active.is-custom-purple.is-outlined:hover, 
.button-renew.active.is-custom-purple.is-outlined:focus, 
.button-renew.active.is-custom-purple.is-outlined.is-focused,
.button-renew.active.is-custom-purple.is-outlined.is-hovered {
  background-color: rgb(51, 204, 149);
  border-color: rgb(51, 204, 149);
  color: #fff;
}